import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Layout from 'components/layout';
import SEO from 'components/seo';
import Header from 'components/Header';
import Products from 'components/Products';
import Link from 'components/Link';
import i18nx from 'components/locale/i18nx';
import Categories from 'components/Products/Categories';


const ProductsPage = ({
  pageContext,
  data,
  location,
}) => {
  return (
    <Layout>
      <SEO
        lang={pageContext.lang}
        title={i18nx('products')}
      />
      <Header lang={pageContext.lang}>
        <div className="ss_breadcrumb text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1>{i18nx('products')}</h1>
                <ul>
                  <li><Link to="/">{i18nx('home')}</Link><span> / {i18nx('products')}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <Categories location={location} />
      <Products
        products={data.allMarkdownRemark.edges}
        location={location}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Products($lang: String!) {
    allMarkdownRemark(filter: { frontmatter: { lang: { eq: $lang }, templateType: {eq: "product"} } }) {
      edges {
        node {
          id
          fields {
            lang
            slug
          }
          frontmatter {
            templateType
            category
            title
            wrapper
            short_description
            price
          }
        }
      }
    }
  }
`

export default ProductsPage;