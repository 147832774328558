import React from 'react';
import qs from 'qs';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { languageKey } from 'components/helper';
import SectionTwo from 'components/Sections/SectionTwo';


const Categories = ({
  location,
}) => {
  const [active, setActive] = React.useState(null);
  const query = location ? qs.parse(location.search, { ignoreQueryPrefix: true }) : {};

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "Home"}}}) {
          edges {
            node {
              fields {
                lang
              }
              frontmatter {
                product_features {
                  label
                  headden
                  image
                  features {
                    description
                    image
                    title
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const getbyLanguage = allMarkdownRemark.edges.find(
    data => data.node.fields.lang === languageKey);

  React.useEffect(()=> {
    setActive(query.cat || null)
  }, [query, getbyLanguage])

  return (
    <SectionTwo
      onClick={({ index }) => navigate(`/${languageKey}/products/?cat=${index}`)}
      page={getbyLanguage.node.frontmatter.product_features.features}
      active={active}
    />
  )
}

export default Categories;
