import React from 'react';
import qs from 'qs';
import Link from 'components/Link';
import i18nx from 'components/locale/i18nx';


const Products = ({
  shopData,
  products,
  location,
  ...props
}) => {
  const [productsList, setProductsList] = React.useState([]);
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  React.useEffect(()=> {
    if (query.cat) {
      const newProducts = products.filter(({ node: { frontmatter } }) =>
        frontmatter.category === query.cat);
  
        setProductsList(newProducts)
    }

    if (!query.cat) {
      setProductsList(products)
    }
  }, [query.cat])

  console.log(productsList)
  return (
    <section className="ss_product ss_section_six ss_shop pt-0">
      <div className="container-fluid">
        <div className="row">
          {productsList?.map(({ node: { frontmatter, fields } }, i) => (
            <div key={i} className="col-lg-4 col-md-6 col-sm-6">
              <div className="ss_six_product" data-wow-delay="0.1s" data-wow-duration="1s" style={{visibility: 'visible', animationDuration: '1s', animationDelay: '0.1s', animationName: 'fadeIn'}}>
                <Link slug={fields.slug}>
                <div
                  className="ss_six_p_img ss_box_bg"
                  style={{
                    height: 400,
                  }}
                >
                  <img className="img-fluid" src={frontmatter.wrapper} alt="wrapper" />
                </div>	
                </Link>
                <Link path={fields.slug}>
                  <div className="ss_product_content ss_box_bg">
                      <h2>{frontmatter.title} <span>{frontmatter.price}</span></h2>
                    <p>{frontmatter.short_description}</p>
                  </div>
                </Link>
              </div>
            </div>
          ))}
          {products && !products.length && 
          <div className="text-center w-100">{i18nx('no_products_section')}</div>}
        </div>	
      </div>	
    </section>
  )  
}
export default Products;
